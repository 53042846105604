import React, { useEffect, useState, useRef } from "react";
import classes from "./reports.module.css";
import Navbar from "../Navbar/Navbar";
import camera from "../Images/camera.png";
import list from "../Images/list.png";
import grid from "../Images/grid.png";
import PdfIcons from "./PdfIcons";
import axios from "axios";
import pdficon from "../Images/pdficon.png";
import { json, useNavigate } from "react-router-dom";
import SignOut from "../SignOut/SignOut";
import dropdownIcon from "../Images/drop.png";
import searchbutton from "../Images/searchbutton.png";
import userdata from "../typesofusers.json";
import Papa from "papaparse";
import csvFile from "../../src/data_to_import.xlsx";
import * as XLSX from "xlsx";

const Reports1 = () => {
  const navigate = useNavigate();

  const [data, setdata] = useState([]);
  const [cont, setCont] = useState(false);
  const [totThatMember, settotThatMember] = useState(0);
  const [number, setnumber] = useState("");
  const [searched, setsearched] = useState(false);
  const [showdata, setshowdata] = useState([]);
  const [alldata, setalldata] = useState([]);
  const [allendusers, setallendusers] = useState([]);
  const [allapollousers, setallapollousers] = useState([]);
  const [noalldata, setnoalldata] = useState(null);
  const [totenduser, settotenduser] = useState(null);
  const [dataCount, setDataCount] = useState(0);
  const [name, setname] = useState("");
  const [totaltests, settotaltests] = useState();

  const [csvData, setCsvData] = useState([]);

  const [jsonData, setjsonData] = useState();

  //read csv file and convert into every json object
  // useEffect(() => {
  //   const readExcelFile = async () => {
  //     try {
  //       // Fetch the CSV file
  //       const response = await fetch(csvFile);
  //       console.log('Response:', response);

  //       // Convert response to array buffer
  //       const data = await response.arrayBuffer();
  //       console.log('ArrayBuffer:', data);

  //       // Read the array buffer as an Excel file
  //       const workbook = XLSX.read(data, { type: 'array' });
  //       console.log('Workbook:', workbook);

  //       // Get the first sheet
  //       const firstSheetName = workbook.SheetNames[0];
  //       const worksheet = workbook.Sheets[firstSheetName];
  //       console.log('Worksheet:', worksheet);

  //       // Convert the worksheet to JSON
  //       const jsonData = XLSX.utils.sheet_to_json(worksheet, { defval: "" });
  //       console.log('JSON Data:', jsonData);

  //       // Update state with the JSON data
  //       setjsonData(jsonData);
  //     } catch (error) {
  //       console.error("Error reading Excel file:", error);
  //     }
  //   };

  //   readExcelFile();
  // }, []);

  useEffect(() => {
    console.log(jsonData);
  }, [jsonData]);

  // save data of csv file in mongodb
  // useEffect( () => {
  //   if(accno==='req_user'){
  //     // console.log('this user insert')
  //     for (const key in jsonData) {
  //       if (jsonData.hasOwnProperty(key)) {
  //         const element = jsonData[key];
  //         try {
  //           console.log(element)
  //           axios
  //             .post("https://iterve.aivot.ai/apollouser1", element, {
  //               params: { accno },
  //             })
  //         } catch (error) {
  //           console.error(`Failed to send ${key}:`, error);
  //         }
  //       }
  //     }
  //   }
  // },[])

  var allKnownData = [];

  const searchInputRef = useRef(null);

  var accno = localStorage.getItem("accno");
  console.log("inside demo reports", accno);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        searchInputRef.current &&
        !searchInputRef.current.contains(event.target)
      ) {
        setsearched(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const vitalsData = {
    name: "patient1",
    phoneNumber: "9073311543",
    email: "a@a.b",
    diabetic: "yes",
    activityFactor: 1,
    gender: "Male",
    age: "27",
    height: "180",
    weight: "70",
    allowable_blood_loss: "10",
    bmi: "25.5",
    body_fat: "18.3",
    body_water: "60.8",
    breathing_rate: "16",
    cardiac_index: "3.2",
    cardiac_output: "5.7",
    dbp: "80",
    hdl: "50",
    hba1c: "5.4",
    heart_rate: "72",
    hematocrit: "42",
    hemoglobin: "14.5",
    ibi: "800",
    ldl: "90",
    mean_atrial_pressure: "95",
    mean_corpuscular_hemoglobin: "32",
    mean_corpuscular_volume: "90",
    mean_plasma_glucose: "100",
    oxygen_saturation: "98",
    pulse_pressure: "40",
    pulse_rate_quotient: "1.8",
    rbc_count: "5.2",
    rmssd: "25",
    random_blood_sugar: "120",
    sbp: "120",
    sd1: "10",
    sd2: "20",
    sdnn: "40",
    stress_index: "70",
    stroke_volume: "70",
    subcutaneous_fat: "22.5",
    total_cholestrol: "180",
    triglycerides: "120",
    vldl: "24",
    visceral_fat: "15.2",
  };

  // function signoutfunc() {
  //   localStorage.removeItem("accno");
  //   axios
  //     .put("https://iterve.aivot.ai/apollouser", {
  //       params: { accno: accno, deviceId: "" },
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //     });
  //   navigate("/login");
  // }

  function search(phone) {
    if (!accno || !phone) return setshowdata([]);
    if (phone.length < 10) {
      setshowdata([]);
      return alert("please provide a valid 10 digit number");
    }
    axios
      .get("https://iterve.aivot.ai/apollouser1/fetchVitalsDataForNumber", {
        params: { accno: accno, phone: phone },
      })
      .then((res) => {
        if (res.data.message === "No phone user found") {
          setshowdata([]);
          setnumber("");
          return alert("No vitals found for the given number");
        } else {
          setshowdata(res.data[0].vitalsData);
        }
      });
  }

  function findObjectByPhoneNumber(phoneNumber, jsonArray) {
    for (let i = 0; i < jsonArray.length; i++) {
      if (jsonArray[i].phone === phoneNumber) {
        return jsonArray[i];
      }
    }
    return null;
  }

  function allusersadmin() {
    console.log("allusers", allapollousers, allendusers);
    for (let i = 0; i < allapollousers.length; i++) {
      if (
        allapollousers[i].accountNumber !== "a" &&
        allapollousers[i].accountNumber !== "s" &&
        allapollousers[i].accountNumber !== "demo"
      ) {
        for (let j = 0; j < allapollousers[i].vitalsData.length; j++) {
          allKnownData.push(allapollousers[i].vitalsData[j]);
        }
      }
    }
    for (let i = 0; i < allendusers.length; i++) {
      allKnownData.push(allendusers[i]);
    }
    // console.log(allKnownData);
    setshowdata(allKnownData);
    setdata(allKnownData);
  }

  function convertToCSV(data) {
    console.log(data);
    const headers = Object.keys(data[0]);
    const escapeContent = (content) => {
      if (
        typeof content === "string" &&
        (content.includes(",") ||
          content.includes("\n") ||
          content.includes('"'))
      ) {
        return `"${content.replace(/"/g, '""')}"`;
      }
      return content;
    };
    const csvContent =
      headers.join(",") +
      "\n" +
      data
        .map((row) =>
          headers
            .map((header) =>
              escapeContent(
                row[header] instanceof Date
                  ? row[header].toLocaleString()
                  : row[header]
              )
            )
            .join(",")
        )
        .join("\n");
    return csvContent;
  }

  function downloadCSV(csvContent, fileName) {
    const blob = new Blob([csvContent], { type: "text/csv" });
    const link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);
    link.download = fileName;
    link.click();
  }

  function convertToCSV1(data) {
    const headers = Object.keys(data[0]);

    const formatRGBArray = (content) => {
      if (content && content.startsWith("[")) {
        console.log("supposed to be RGB array", content);

        // Remove the outer square brackets and spaces
        content = content.slice(1, -1).trim();

        // Split the string into individual RGB strings
        let rgbArray = content.split("],[");

        // Remove leading "[" from the first element and trailing "]" from the last element
        rgbArray[0] = rgbArray[0].replace("[", "");
        rgbArray[rgbArray.length - 1] = rgbArray[rgbArray.length - 1].replace(
          "]",
          ""
        );

        // Convert each RGB string into the desired format
        let formattedArray = rgbArray.map((rgb) => {
          let values = rgb.split(",").map((value) => value.trim());
          return `(${values.join("\t ")})`;
        });

        return formattedArray.join("; ") + ";";
      }
      return content;
    };

    const csvContent = [
      headers.join(","), // Header row
      ...data.map((row) =>
        headers
          .map((header) =>
            formatRGBArray(
              row[header] instanceof Date
                ? row[header].toLocaleString()
                : row[header]
            )
          )
          .join(",")
      ),
    ].join("\n");

    return csvContent;
  }

  function downloadCSV1(csvContent, fileName) {
    const blob = new Blob([csvContent], { type: "text/csv" });
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = fileName;
    link.click();
  }

  //get all the tests of the current user to be downloaded as csv

  function getalltests(accno) {
    var alltests = [];
    axios
      .get("https://iterve.aivot.ai/apollouser1", { params: { accno: accno } })
      .then((res) => {
        console.log(res.data);
        for (var i = 0; i < res.data.vitalsData.length; i++) {
          // if (
          //   res.data.vitalsData[i].createdAt.startsWith("2024-04-14") ||
          //   res.data.vitalsData[i].createdAt.startsWith("2024-04-13")
          // ) {
          alltests.push(res.data.vitalsData[i]);
          // }
        }
        console.log(alltests);
        const csvContent = convertToCSV(alltests);
        downloadCSV(csvContent, "alltests.csv");
      })
      .catch((e) => {
        console.log("error", e);
      });
  }

  //new vitalsdata of demo_1 user to upload to database of the new user

  function uploadDataToDatabase(vitalsData, rgb_values, objid) {
    console.log("Calling timestamp", performance.now());
    axios
      .post("https://iterve.aivot.ai/apollouser1/t34sh", vitalsData, {
        params: { accno },
      })
      .then((response) => {
        console.log(response);
        var form = new FormData();
        form.append("rgb_values", rgb_values);
        form.append("sno", objid);
        form.append("username", accno);
        console.log([...form.entries()]);
        axios
          .post("https://iterve.aivot.ai/rgbvalue", {
            params: { accno: accno, rgbid: rgb_values, rgbd: objid },
          })
          .then((res) => {
            console.log(res.data);
          })
          .catch((error) => {
            console.error("Error:", error);
          });
      })

      .catch((error) => {
        console.error("Error:", error);
      });
  }

  //store the demo_1 data newly in new user. Change the new user in the backend

  // useEffect(() => {
  //   axios.get("https://iterve.aivot.ai/apollouser1/getvitalsdatawithdetails", {params:{accno:'Demo_1'}})
  //   .then((res) => {
  //     console.log(res.data)
  //     for(let i=0;i<res.data.length;i++){
  //       console.log(res.data[i])
  //       var formData = new FormData()
  //       formData.append('name', res.data[i].name)
  //       formData.append('phoneNumber', res.data[i].phoneNumber)
  //       formData.append('diabetic', res.data[i].diabetic)
  //       formData.append('activity_factor', res.data[i].activityFactor)
  //       formData.append('gender', res.data[i].gender)
  //       formData.append('age', res.data[i].age)
  //       formData.append('height', res.data[i].height)
  //       formData.append('weight',res.data[i].weight);
  //       formData.append('subject_id', '1001')
  //       formData.append('whole_rgbData',res.data[i].rgb_values);
  //       console.log([...formData.entries()])

  //       console.log([...formData.entries()])

  //       fetch(`https://demo.aivot.ai/process_rgb_data`, {
  //         method: "POST",
  //         body: formData,
  //           // headers: {
  //           //     'Content-Type': 'application/json'
  //           // },
  //       }).then((response) => {
  //         response.json().then((r) => {
  //           console.log(r)
  //           const vitalsData = {
  //             name: res.data[i].name,
  //             phoneNumber: res.data[i].phoneNumber,
  //             email: res.data[i].email,
  //             diabetic: res.data[i].diabetic,
  //             activityFactor: res.data[i].activityFactor,
  //             gender: res.data[i].gender,
  //             age: res.data[i].age,
  //             height: res.data[i].height,
  //             weight: res.data[i].weight,
  //             allowable_blood_loss:
  //               r["Allowable Blood Loss [milliliters]"],
  //             bmi: r["BMI [kg/m2]"],
  //             body_fat: r["Body Fat [percentage]"],
  //             body_water: r["Body Water [percentage]"],
  //             breathing_rate: r["Breathing Rate [breaths per minute]"],
  //             cardiac_index: r["Cardiac Index [L/(min·m²)]"],
  //             cardiac_output: r["Cardiac Output [L/min]"],
  //             dbp: r["DBP [mmHg]"],
  //             hdl: r["HDL [mg/dL]"],
  //             hba1c: r["HbA1c [percentage]"],
  //             heart_rate: r["Heart Rate [beats per minute]"],
  //             hematocrit: r["Hematocrit [percentage]"],
  //             hemoglobin: r["Hemoglobin [g/dL]"],
  //             ibi: r["IBI [miliseconds]"],
  //             ldl: r["LDL [mg/dL]"],
  //             mean_atrial_pressure: r["Mean Artial Pressure [mmHg]"],
  //             mean_corpuscular_hemoglobin:
  //               r["Mean Corpuscular Hemoglobin [pg/cells]"],
  //             mean_corpuscular_volume: r["Mean Corpuscular Volume [fl]"],
  //             mean_plasma_glucose: r["Mean Plasma Glucose [mg/dL]"],
  //             oxygen_saturation: r["Oxygen Saturation [percentage]"],
  //             pulse_pressure: r["Pulse Pressure [mmHg]"],
  //             pulse_rate_quotient:
  //               r["Pulse Rate Quotient [beats per breath]"],
  //             rbc_count: r["RBC Count [million cells/mcL]"],
  //             rmssd: r["RMSSD [miliseconds]"],
  //             random_blood_sugar: r["Random Blood Sugar [mg/dL]"],
  //             sbp: r["SBP [mmHg]"],
  //             sd1: r["SD1 [miliseconds]"],
  //             sd2: r["SD2 [miliseconds]"],
  //             sdnn: r["SDNN [miliseconds]"],
  //             stress_index: r["Stress Index"],
  //             stroke_volume: r["Stroke Volume [milliliters]"],
  //             subcutaneous_fat: r["Subcutaneous Fat [percentage]"],
  //             total_cholestrol: r["Total Cholesterol [mg/dL]"],
  //             triglycerides: r["Triglycerides [mg/dL]"],
  //             vldl: r["VLDL [mg/dL]"],
  //             visceral_fat: r["Visceral Fat [percentage]"],
  //             t3:r['(T3) Triiodothyronine [ng/dL]'],
  //             t4:r['(T4) Thyroxine [μg/dL]'],
  //             tsh: r['(TSH) Thyroid Stimulating Hormone [μIU/mL]'],
  //             K_value: r['K_value'],
  //             Spectral_Energy: r['Spectral_Energy'],
  //             LF_Power: r['LF_Power'],
  //             HF_Power: r['HF_Power'],
  //             Amp: r['Amp'],
  //             Amid: r['Amid'],
  //             Dmid: r['Dmid'],
  //             mNPV: r['mNPV'],
  //             s: r['s'],
  //             pnn20: r['pnn20'],
  //             pnn50: r['pnn50'],
  //             hr_mad: r['hr_mad'],
  //             vlf: r['vlf'],
  //             SDSD: r['SDSD'],
  //             lf_hf: r['lf_hf'],
  //             lf: r['lf'],
  //             hf: r['hf'],
  //             PNS: r['PNS'],
  //             SNS: r['SNS'],
  //             BSA: r['BSA'],
  //             LBM: r['LBM'],
  //             BFM: r['BFM'],
  //             BMR: r['BMR'],
  //             DCN: r['DCN'],
  //             HemoGlobin_Old: r['HemoGlobin_Old'],
  //             HemoGlobinFemaleBelow49: r['HemoGlobinFemaleBelow49'],
  //             HemoGlobinMaleBelow49: r['HemoGlobinMaleBelow49'],
  //             RBS_Old: r['RBS_Old'],
  //             SBP_Old: r['SBP_Old'],
  //             DBP_Old: r['DBP_Old']
  //           };
  //           console.log(vitalsData);
  //           var rgb_values = res.data[i].rgb_values;
  //           var objid = res.data[i]._id;
  //           uploadDataToDatabase(vitalsData,rgb_values,objid);
  //         })

  //       })
  //     }
  //   })
  //   .catch((err) => {
  //     console.log(err);
  //   })
  //   console.log('calling useeffect')
  // },[])

  // useEffect(() => {
  //   axios
  //     .get("https://iterve.aivot.ai/apollouser1/all", {
  //       params: { accno },
  //     })
  //     .then((res) => {
  //       console.log(res.data);
  //       const csvContent = convertToCSV(res.data);
  //       downloadCSV(csvContent, "demo1data.csv");
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // });

  // useEffect(() => {
  //   axios.get('https://iterve.aivot.ai/apollouser1', {params:{accno}})
  //   .then((res) => {
  //     console.log(res)
  //     const csvContent = convertToCSV(res.data.vitalsData);
  //     downloadCSV(csvContent, "alldemo1vitals.csv");
  //   })
  //   .catch((err) => {
  //     console.log(err);
  //   });
  // },[]);

  useEffect(() => {
    if (!accno) return;
    if (localStorage.getItem("accno") === "SA_Sub") {
      axios
        .get("https://iterve.aivot.ai/apollouser1/vitalsDataSize", {
          params: { accno: "SA_Master" },
        })
        .then((res) => {
          // console.log(res.data)
          // getalltests(accno);
          setDataCount(res.data.vitalsDataSize);
          if (accno !== "a" && accno !== "s" && accno !== "demo") {
            settotThatMember(res.data.vitalsDataSize);
          }
          setname("SA_Sub");
        });
    } else if (userdata.form2user && userdata.form2user.includes(accno)) {
      axios
        .get("https://iterve.aivot.ai/apollouser1/vitalsDataSize", {
          params: { accno },
        })
        .then((res) => {
          // console.log(res.data)
          // getalltests(accno);
          setDataCount(res.data.vitalsDataSize);
          if (accno !== "a" && accno !== "s" && accno !== "demo") {
            settotThatMember(res.data.vitalsDataSize);
          }
          setname(res.data.username);
        });
    } else if (userdata.form3user && userdata.form3user.includes(accno)) {
      axios
        .get("https://iterve.aivot.ai/palmuser/vitalsDataSize", {
          params: { accno },
        })
        .then((res) => {
          // console.log(res.data);
          // getalltests(accno);
          setDataCount(res.data.vitalsDataSize);
          if (accno !== "a" && accno !== "s" && accno !== "demo") {
            settotThatMember(res.data.vitalsDataSize);
          }
          setname(res.data.username);
        });
    }
    if (accno && accno.startsWith("admin")) {
      allusersadmin();
    }
  }, [allapollousers, allendusers]);

  useEffect(() => {
    var a = 0;

    if (accno && accno.startsWith("admin")) {
      axios
        .get("https://iterve.aivot.ai/apollouser1/all")
        .then((res) => {
          console.log(res.data);

          for (let i = 0; i < res.data.length; i++) {
            if (
              res.data[i].accountNumber !== "a" &&
              res.data[i].accountNumber !== "s" &&
              res.data[i].accountNumber !== "demo"
            ) {
              a = a + res.data[i].vitalsData.length;
            }
          }
          setallapollousers(res.data);
          // console.log(a);
          setnoalldata(a);
        })
        .catch((error) => {
          console.log(error);
        });

      axios
        .get("https://iterve.aivot.ai/enduser/all")
        .then((res) => {
          // console.log(res.data);
          // console.log(res.data.length);
          settotenduser(res.data);
          setallendusers(res.data);
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      axios
        .get("https://iterve.aivot.ai/apollouser1/allcount")
        .then((res) => setnoalldata(res.data.totalVitalsData));

      axios
        .get("https://iterve.aivot.ai/enduser/allcount")
        .then((res) => {
          // console.log("enduserCount", res.data.cnt);
          settotenduser(res.data.cnt);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, []);

  function convertDateFormat(dateString) {
    const parts = dateString.split("-");
    const year = parseInt(parts[0]);
    const month = parseInt(parts[1]);
    const day = parseInt(parts[2]);
    const date = new Date(year, month - 1, day);
    const monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    const monthName = monthNames[date.getMonth()];
    const newDateFormat = monthName + " " + day + ", " + year;

    return newDateFormat;
  }

  const pdfContainerSize = () => {
    const dropId = document.getElementById("dropId");
    const pdfcontainer = document.getElementById("pdfcontainer");
    if (cont === false || window.innerWidth < 768) {
      pdfcontainer.style.width = "90vw";
      dropId.style.transform = "scaleY(-1)";
    } else {
      pdfcontainer.style.width = "55vw";
      dropId.style.transform = "scaleY(1)";
    }
    console.log("cont", cont);
  };

  function convertAndDownloadCSV(arrayString) {
    // Parse the array string
    const array = JSON.parse(arrayString);

    // Create CSV content
    let csvContent = "Time,Red Mean,Green Mean,Blue Mean\n";

    // Iterate through the array
    array.forEach((innerArray, index) => {
      // Construct CSV rows
      const row = `${index * 33},${innerArray[0]},${innerArray[1]},${
        innerArray[2]
      }\n`;
      csvContent += row;
    });

    // Create a Blob object with the CSV content
    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8" });

    // Create a temporary anchor element
    const a = document.createElement("a");
    a.href = window.URL.createObjectURL(blob);

    // Set the file name
    a.download = "rgbRoute1.csv";

    // Append the anchor element to the body
    document.body.appendChild(a);

    // Simulate click on the anchor to trigger download
    a.click();

    // Remove the anchor from the body
    document.body.removeChild(a);
  }

  const findrecord = () => {
    axios.get("https://iterve.aivot.ai/rgbvalue").then((res) => {
      console.log(res.data.rgb_values);
      convertAndDownloadCSV(res.data.rgb_values);
    });
  };

  useEffect(() => {
    if (
      localStorage.getItem("accno") !== "SA_Sub" &&
      localStorage.getItem("accno") !== "SA_Master"
    ) {
      // Only call pdfContainerSize when cont is true
      pdfContainerSize();
    }
  }, [cont]);
  const dropFunc = () => {
    setCont(!cont);
  };

  return (
    <>
      {localStorage.getItem("accno") !== "SA_Sub" ? (
        <div className={classes.container}>
          <Navbar />
          <div className={classes.heading}>
            <div className={classes.circle}></div>
            <div className={classes.texts}>
              <div className={classes.text1}>Welcome,</div>
              <div className={classes.text2}>{name}</div>
            </div>
            {localStorage.getItem("accno") !== "SA_Master" ? (
              <img src={camera} className={classes.camera} />
            ) : (
              <></>
            )}
            {localStorage.getItem("accno") !== "SA_Master" ? (
              <div
                className={classes.newbutton}
                onClick={() => {
                  if (
                    accno === "Guest" ||
                    accno === "Test_Dosha" ||
                    accno === "HHJamSaheb"
                  ) {
                    navigate("/selftest2", { state: "fromApolloMember" });
                  } else if (
                    userdata.form2user &&
                    userdata.form2user.includes(accno)
                  ) {
                    navigate("/selftest1", { state: "fromApolloMember" });
                  } else if (
                    userdata.form3user &&
                    userdata.form3user.includes(accno)
                  ) {
                    navigate("/selftestpalm", { state: "fromApolloMember" });
                  } else {
                    navigate("/selftest", { state: "fromApolloMember" });
                  }
                }}
              >
                New Test
              </div>
            ) : (
              <></>
            )}
          </div>
          <div className={classes.pdfhead}>
            {localStorage.getItem("accno") !== "SA_Sub" && (
              <div className={classes.prevreports}>Previous Reports</div>
            )}
            {/* <img src={list} className={classes.listicon} />
        <img src={grid} className={classes.listicon} /> */}
            {localStorage.getItem("accno") !== "SA_Master" ? (
              <div className={classes.search} ref={searchInputRef}>
                <div className={classes.searchbar}>
                  {searched ? (
                    <>
                      <input
                        type="text"
                        onChange={(e) => setnumber(e.target.value)}
                        className={classes.inputsearch}
                        value={number}
                        onKeyDown={(e) => {
                          if (e.key === "Enter") {
                            search(number);
                          }
                        }}
                      />
                    </>
                  ) : (
                    <></>
                  )}
                  <img
                    src={searchbutton}
                    className={classes.listicon}
                    onClick={() => {
                      setsearched((val) => !val);
                      search(number);
                    }}
                  />
                </div>
              </div>
            ) : (
              <></>
            )}
            {localStorage.getItem("accno") !== "SA_Master" ? (
              <div
                className={classes.assessmentHeadContainer}
                onClick={dropFunc}
              >
                <div className={classes.dropElement}>
                  <div className={classes.assesmentText}>
                    Assessment Counter
                  </div>
                  <img
                    src={dropdownIcon}
                    className={classes.dropdownIcon}
                    id="dropId"
                  />
                </div>
              </div>
            ) : (
              <>
                <div className={classes.assesmentText1}>
                  Tests Performed:{totThatMember}
                </div>
              </>
            )}
          </div>
          {showdata ? (
            <div>
              <div className={classes.pdfAssessmentContainer}>
                <div className={classes.pdfcontainer} id="pdfcontainer">
                  {dataCount > 0 && accno !== undefined && (
                    <PdfIcons
                      data={data}
                      showdata={showdata}
                      navigate={navigate}
                      accno={accno}
                      dataCount={dataCount}
                    />
                  )}
                </div>
                {cont ? (
                  <div className={classes.assessmentContainer}>
                    <div className={classes.dropElement}>
                      <div className={classes.dropText}>
                        Test Record by {name}:
                      </div>
                      {accno.startsWith("admin") ? (
                        <div className={classes.countText}>-</div>
                      ) : (
                        <div className={classes.countText}>{totThatMember}</div>
                      )}
                    </div>
                    <div className={classes.dropElement}>
                      {accno.startsWith("admin") ? (
                        <div className={classes.dropText}>
                          Test Record by other 12 users:
                        </div>
                      ) : (
                        <div className={classes.dropText}>
                          Test Record by other users:
                        </div>
                      )}
                      {accno.startsWith("admin") ? (
                        <div className={classes.countText}>-</div>
                      ) : (
                        <div className={classes.countText}>
                          {noalldata - totThatMember}
                        </div>
                      )}
                    </div>
                    <div className={classes.dropElement}>
                      <div className={classes.dropText}>
                        Test Record by End User:
                      </div>
                      {accno.startsWith("admin") ? (
                        <div className={classes.countText}>-</div>
                      ) : (
                        <div className={classes.countText}>{totenduser}</div>
                      )}
                    </div>
                    <div className={classes.dropElement}>
                      <div className={classes.dropText}>
                        Test Record by Iterve Teams:
                      </div>
                      <div className={classes.countText}>
                        {noalldata + totenduser}
                      </div>
                    </div>
                  </div>
                ) : (
                  ""
                )}
              </div>
              <div className={classes.signoutSec}>
                <SignOut />
              </div>
            </div>
          ) : (
            <div className={classes.signoutSec}>
              <SignOut />
            </div>
          )}
          {/* <button onClick={findrecord}>Find</button> */}
        </div>
      ) : (
        <>
          <div className={classes.container}>
            <Navbar />
            <div className={classes.heading}>
              <div className={classes.circle}></div>
              <div className={classes.texts}>
                <div className={classes.text1}>Welcome,</div>
                <div className={classes.text2}>{name}</div>
              </div>
            </div>
            <div className={classes.pdfhead}>
              {localStorage.getItem("accno") !== "SA_Sub" && (
                <div className={classes.prevreports}>Previous Reports</div>
              )}
              {/* <img src={list} className={classes.listicon} />
        <img src={grid} className={classes.listicon} /> */}
              {localStorage.getItem("accno") !== "SA_Sub" ? (
                <div className={classes.search} ref={searchInputRef}>
                  <div className={classes.searchbar}>
                    {searched ? (
                      <>
                        <input
                          type="text"
                          onChange={(e) => setnumber(e.target.value)}
                          className={classes.inputsearch}
                          value={number}
                          onKeyDown={(e) => {
                            if (e.key === "Enter") {
                              search(number);
                            }
                          }}
                        />
                      </>
                    ) : (
                      <></>
                    )}
                    <img
                      src={searchbutton}
                      className={classes.listicon}
                      onClick={() => {
                        setsearched((val) => !val);
                        search(number);
                      }}
                    />
                  </div>
                </div>
              ) : (
                <></>
              )}
              {localStorage.getItem("accno") !== "SA_Sub" ? (
                <div
                  className={classes.assessmentHeadContainer}
                  onClick={dropFunc}
                >
                  <div className={classes.dropElement}>
                    <div className={classes.assesmentText}>
                      Assessment Counter
                    </div>
                    <img
                      src={dropdownIcon}
                      className={classes.dropdownIcon}
                      id="dropId"
                    />
                  </div>
                </div>
              ) : (
                <></>
              )}
            </div>
            {showdata ? (
              <div>
                <div className={classes.pdfAssessmentContainer}>
                  <div id="pdfcontainer"></div>

                  {cont ? (
                    <div className={classes.assessmentContainer}>
                      {localStorage.getItem("accno") === "SA_Sub" ? (
                        <div className={classes.dropElement}>
                          <div className={classes.dropText}>
                            Test Record by {name}:
                          </div>
                          {accno.startsWith("admin") ? (
                            <div className={classes.countText}>-</div>
                          ) : (
                            <div className={classes.countText}>
                              {totThatMember}
                            </div>
                          )}
                        </div>
                      ) : (
                        <>
                          <div className={classes.dropElement}>
                            <div className={classes.dropText}>
                              Test Record by {name}:
                            </div>
                            {accno.startsWith("admin") ? (
                              <div className={classes.countText}>-</div>
                            ) : (
                              <div className={classes.countText}>
                                {totThatMember}
                              </div>
                            )}
                          </div>
                          <div className={classes.dropElement}>
                            {accno.startsWith("admin") ? (
                              <div className={classes.dropText}>
                                Test Record by other 12 users:
                              </div>
                            ) : (
                              <div className={classes.dropText}>
                                Test Record by other users:
                              </div>
                            )}
                            {accno.startsWith("admin") ? (
                              <div className={classes.countText}>-</div>
                            ) : (
                              <div className={classes.countText}>
                                {noalldata - totThatMember}
                              </div>
                            )}
                          </div>
                          <div className={classes.dropElement}>
                            <div className={classes.dropText}>
                              Test Record by End User:
                            </div>
                            {accno.startsWith("admin") ? (
                              <div className={classes.countText}>-</div>
                            ) : (
                              <div className={classes.countText}>
                                {totenduser}
                              </div>
                            )}
                          </div>
                          <div className={classes.dropElement}>
                            <div className={classes.dropText}>
                              Test Record by Iterve Teams:
                            </div>
                            <div className={classes.countText}>
                              {noalldata + totenduser}
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                  ) : (
                    ""
                  )}
                </div>
                <div className={classes.testRow}>
                  <div className={classes.miniCont}>
                    <div className={classes.testCont}>
                      Test Performed - {totThatMember}
                    </div>
                  </div>
                  <div className={classes.miniCont}>
                    <div
                      className={classes.testBtn}
                      onClick={() => {
                        if (
                          accno === "Guest" ||
                          accno === "Test_Dosha" ||
                          accno === "HHJamSaheb"
                        ) {
                          navigate("/selftest2", { state: "fromApolloMember" });
                        } else if (
                          userdata.form2user &&
                          userdata.form2user.includes(accno)
                        ) {
                          navigate("/selftest1", { state: "fromApolloMember" });
                        } else if (
                          userdata.form3user &&
                          userdata.form3user.includes(accno)
                        ) {
                          navigate("/selftestpalm", {
                            state: "fromApolloMember",
                          });
                        } else {
                          navigate("/selftest", { state: "fromApolloMember" });
                        }
                      }}
                    >
                      New Test
                    </div>
                  </div>
                </div>
                <div className={classes.signoutSec}>
                  <SignOut />
                </div>
              </div>
            ) : (
              <div className={classes.signoutSec}>
                <SignOut />
              </div>
            )}
            {/* <button onClick={findrecord}>Find</button> */}
          </div>
        </>
      )}
    </>
  );
};

export default Reports1;
