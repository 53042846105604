import React, { useState, useEffect, useRef } from "react";
import Webcam from "react-webcam";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import classes from "./WebcamRecorder.module.css";
import extractRGBs from "./selftestUtil.js";
import * as faceLandmarksDetection from "@tensorflow-models/face-landmarks-detection";
import "@tensorflow/tfjs-core";
import "@tensorflow/tfjs-backend-webgl";
import "@mediapipe/face_mesh";
import { FaceMesh } from "@mediapipe/face_mesh";
import { updateInput } from "./formUtil";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";

function uploadDataToDatabase(vitalsData, rgbd, navigate) {
  const accno = localStorage.getItem("accno");
  const page = localStorage.getItem("page");

  console.log(accno, page);

  if (page === "apollomember") {
    console.log(vitalsData);
    axios
      .post("https://iterve.aivot.ai/apollouser", vitalsData, {
        params: { accno },
      })
      .then((response) => {
        const rgbid = response.data;
        console.log(rgbid);
        axios
          .post("https://iterve.aivot.ai/rgbvalue", {
            params: { accno: accno, rgbid: rgbid, rgbd: rgbd },
          })
          .then((res) => {
            console.log(res);
            vitalsData["id"] = rgbid;
            vitalsData["_id"] = rgbid;
            if (accno.startsWith("Hiims") || accno === "NDC_DC") {
              navigate("/shortvitals", { state: vitalsData });
            } else {
              navigate("/vitals", { state: vitalsData });
            }
          });
      })

      .catch((error) => {
        console.error("Error:", error);
      });
  } else {
    console.log("end user", vitalsData);
    axios
      .post("https://iterve.aivot.ai/enduser", vitalsData)
      .then(async (response) => {
        console.log(response.data);

        const rgbid = response.data._id;
        const accno = response.data.name;
        axios
          .post("https://iterve.aivot.ai/rgbvalue", {
            params: { accno: accno, rgbid: rgbid, rgbd: rgbd },
          })
          .then((res) => {
            console.log(res);
            vitalsData["id"] = rgbid;
            vitalsData["_id"] = rgbid;
            if (accno === "Hiims_data" || accno === "NDC_DC") {
              navigate("/shortvitals", { state: vitalsData });
            } else {
              navigate("/vitals", { state: vitalsData });
            }
          });
      })
      .catch((error) => {
        console.log();
      });
  }
}

const generateCustomId = () => {
  const uuid = uuidv4();
  const letters = uuid
    .replace(/[^a-zA-Z]/g, "")
    .slice(0, 2)
    .toUpperCase(); // Get first two letters and convert to uppercase
  const digits = uuid.replace(/\D/g, "").slice(0, 4); // Remove non-digits and get the first 4 digits
  return `${letters}${digits}`;
};

function WebcamRecorder(props) {
  const interval = 500;
  const navigate = useNavigate();

  const accno = localStorage.getItem("accno");
  const page = localStorage.getItem("page");

  const maxTime = 60 * 1000;
  const fps = 30;

  const {
    startRecording,
    setRecording,
    setLoading,
    token,
    formData,
    alertWDC,
    isAlertActive,
    setAlertActive,
  } = props;
  const webcamRef = useRef();
  const [progress, setProgress] = useState(0);
  const [recorder, setRecorder] = useState(null);
  const [model, setModel] = useState(null);
  const [detectionInProgress, setDetectionInProgress] = useState(false);
  const [senddata, setsenddata] = useState({});
  const [detectionProcessCount, setDetectionProcessCount] = useState(0);
  const [cameraMode, setcameraMode] = useState("user");

  const detectionInProgressRef = useRef(detectionInProgress);

  useEffect(() => {
    detectionInProgressRef.current = detectionInProgress; // Update the ref when detectionInProgress changes
  }, [detectionInProgress]);

  useEffect(() => {
    const model_setter = async () => {
      const model = faceLandmarksDetection.SupportedModels.MediaPipeFaceMesh;
      const detectorConfig = {
        // runtime: "tfjs",
        // // detectorModelUrl: `${process.env.PUBLIC_URL}/face_detector/model.json`,
        // detectorModelUrl: `https://iterve.aivot.ai/model/model.json`,
        runtime: "mediapipe", // or 'tfjs'
        solutionPath: "https://cdn.jsdelivr.net/npm/@mediapipe/face_mesh",
      };
      const detector = await faceLandmarksDetection.createDetector(
        model,
        detectorConfig
      );
      console.log(detector);
      setModel(detector);
    };
    model_setter();
  }, []);

  useEffect(() => {
    console.log("detection state changed", detectionInProgress);
    if (detectionInProgress) {
      console.log("called through useEffect");
      console.log("loading starts here", performance.now());
      const ss = webcamRef.current.getScreenshot({ width: 320, height: 240 });

      const loadImage = (src) =>
        new Promise((imgResolve) => {
          const img = new Image();
          img.onload = () => {
            imgResolve(img);
          };
          img.src = src;
        });

      loadImage(ss)
        .then((img) => model.estimateFaces(img))
        .then(console.log("loading resolves here", performance.now()))
        .then(
          setTimeout(() => {
            setLoading(false);
            document
              .getElementById("webcam-container")
              .scrollIntoView({ behavior: "smooth", block: "center" });
          }, 1000)
        )
        .then(
          extractRGBs(
            webcamRef,
            fps,
            model,
            detectionInProgressRef,
            setProgress,
            setDetectionProcessCount
          ).then((res) => {
            setProgress(0);
            setRecording(false);
            const rgbDatas = res[0];
            const fps = res[1];

            if (!rgbDatas) {
              setDetectionInProgress(false);
              setAlertActive(true);
              window.alert(
                "Make sure that your face is in front of the camera"
              );
              setTimeout(() => setAlertActive(false), 100);
              return;
            }

            if (rgbDatas === true) return; // detection is not in progress

            // const formData = updateInput();
            formData.append(`whole_rgbData`, JSON.stringify(rgbDatas));
            const customId = generateCustomId();
            formData.append("subject_id", customId);
            formData.append("meanTimeElapsed", formData.get("mealTimeElapsed"));
            console.log([...formData.entries()]);

            if (!localStorage.getItem("accno").startsWith("Hiims")) {
              return fetch("https://api.iterve.ai/process_iterve_data", {
                method: "POST",
                body: formData,
                //   headers: {
                //     'Content-Type': 'application/json'
                // },
              })
                .then((response) =>
                  response.json().then((res) => {
                    console.log(res);
                    const vitalsData = {
                      ...res,
                      name: formData.get("name"),
                      phoneNumber: formData.get("phoneNumber"),
                      email: formData.get("email"),
                      subjectid: formData.get("subject_id"),
                      diabetic: formData.get("diabetic"),
                      activityFactor: formData.get("activity_factor"),
                      gender: formData.get("gender"),
                      age: formData.get("age"),
                      height: formData.get("height"),
                      weight: formData.get("weight"),
                      allowable_blood_loss:
                        res["Allowable Blood Loss [milliliters]"],
                      bmi: res["BMI [kg/m2]"],
                      body_fat: res["Body Fat [percentage]"],
                      body_water: res["Body Water [percentage]"],
                      breathing_rate:
                        res["Breathing Rate [breaths per minute]"],
                      cardiac_index: res["Cardiac Index [L/(min·m²)]"],
                      cardiac_output: res["Cardiac Output [L/min]"],
                      dbp:
                        localStorage.getItem("accno") === "NDC_DC"
                          ? res["DBP_Old"]
                          : res["DBP [mmHg]"],
                      hdl: res["HDL [mg/dL]"],
                      hba1c: res["HbA1c [percentage]"],
                      heart_rate: res["Heart Rate [beats per minute]"],
                      hematocrit: res["Hematocrit [percentage]"],
                      hemoglobin: res["Hemoglobin [g/dL]"],
                      ibi: res["IBI [miliseconds]"],
                      ldl: res["LDL [mg/dL]"],
                      mean_atrial_pressure: res["Mean Artial Pressure [mmHg]"],
                      mean_corpuscular_hemoglobin:
                        res["Mean Corpuscular Hemoglobin [pg/cells]"],
                      mean_corpuscular_volume:
                        res["Mean Corpuscular Volume [fl]"],
                      mean_plasma_glucose: res["Mean Plasma Glucose [mg/dL]"],
                      oxygen_saturation: res["Oxygen Saturation [percentage]"],
                      pulse_pressure: res["Pulse Pressure [mmHg]"],
                      pulse_rate_quotient:
                        res["Pulse Rate Quotient [beats per breath]"],
                      rbc_count: res["RBC Count [million cells/mcL]"],
                      rmssd: res["RMSSD [miliseconds]"],
                      random_blood_sugar: res["Random Blood Sugar [mg/dL]"],
                      sbp:
                        localStorage.getItem("accno") === "NDC_DC"
                          ? res["SBP_Old"]
                          : res["SBP [mmHg]"],
                      sd1: res["SD1 [miliseconds]"],
                      sd2: res["SD2 [miliseconds]"],
                      sdnn: res["SDNN [miliseconds]"],
                      stress_index: res["Stress Index"],
                      stroke_volume: res["Stroke Volume [milliliters]"],
                      subcutaneous_fat: res["Subcutaneous Fat [percentage]"],
                      total_cholestrol: res["Total Cholesterol [mg/dL]"],
                      triglycerides: res["Triglycerides [mg/dL]"],
                      vldl: res["VLDL [mg/dL]"],
                      visceral_fat: res["Visceral Fat [percentage]"],
                      true_rbs_fbs: formData.get("rbs_fbs"),
                      true_sbp: formData.get("sbp"),
                      true_dbp: formData.get("dbp"),
                      weightChanged: formData.get("weightChanged"),
                      mealTimeElapsed: formData.get("mealTimeElapsed"),
                      hypertension: formData.get("hypertension"),
                      healthIssues: formData.get("healthIssues"),
                      familyDiabetes: formData.get("familyDiabetes"),
                      familyHypertension: formData.get("familyHypertension"),
                      medication: formData.get("medication"),

                      // New fields
                      t3: res["(T3) Triiodothyronine [ng/dL]"],
                      t4: res["(T4) Thyroxine [μg/dL]"],
                      tsh: res["(TSH) Thyroid Stimulating Hormone [μIU/mL]"],
                      blood_volume: res["Blood Volume [mL]"],
                      ibi: res["IBI [miliseconds]"],
                      // mean_arterial_pressure: res["Mean Artial Pressure [mmHg]"],
                      mean_plasma_glucose: res["Mean Plasma Glucose [mg/dL]"],
                      rbc_count: res["RBC Count [million cells/mcL]"],
                      vldl: res["VLDL [mg/dL]"],
                      hr_mad: res["hr_mad"],
                      pnn20: res["pnn20"],
                      pnn50: res["pnn50"],
                      s: res["s"],
                      sd1_sd2: res["sd1/sd2"],
                      vlf: res["vlf"],
                      K_value: res["K_value"],
                      Spectral_Energy: res["Spectral_Energy"],
                      LF_Power: res["LF_Power"],
                      HF_Power: res["HF_Power"],
                      Amp: res["Amp"],
                      Amid: res["Amid"],
                      Dmid: res["Dmid"],
                      mNPV: res["mNPV"],
                    };

                    console.log("res is printed here", res);
                    uploadDataToDatabase(
                      vitalsData,
                      JSON.stringify(rgbDatas),
                      navigate
                    );

                    setsenddata(vitalsData);
                    console.log(senddata);
                    if (token) {
                      axios.delete(`https://iterve.aivot.ai/token`, {
                        params: { token },
                      });
                    }

                    console.log(res, [...formData.entries()]);
                  })
                )
                .catch((e) => console.log(e.message));
            } else {
              return fetch("https://api.iterve.ai/process_iterve_rbs_data", {
                method: "POST",
                body: formData,
                //   headers: {
                //     'Content-Type': 'application/json'
                // },
              })
                .then((response) =>
                  response.json().then((res) => {
                    console.log(res);
                    const vitalsData = {
                      ...res,
                      name: formData.get("name"),
                      phoneNumber: formData.get("phoneNumber"),
                      email: formData.get("email"),
                      subjectid: formData.get("subject_id"),
                      diabetic: formData.get("diabetic"),
                      activityFactor: formData.get("activity_factor"),
                      gender: formData.get("gender"),
                      age: formData.get("age"),
                      height: formData.get("height"),
                      weight: formData.get("weight"),
                      allowable_blood_loss:
                        res["Allowable Blood Loss [milliliters]"],
                      bmi: res["BMI [kg/m2]"],
                      body_fat: res["Body Fat [percentage]"],
                      body_water: res["Body Water [percentage]"],
                      breathing_rate:
                        res["Breathing Rate [breaths per minute]"],
                      cardiac_index: res["Cardiac Index [L/(min·m²)]"],
                      cardiac_output: res["Cardiac Output [L/min]"],
                      dbp:
                        localStorage.getItem("accno") === "NDC_DC" &&
                        !localStorage.getItem("accno").startsWith("Hiims")
                          ? res["DBP_Old"]
                          : res["DBP [mmHg]"],
                      hdl: res["HDL [mg/dL]"],
                      hba1c: res["HbA1c [percentage]"],
                      heart_rate: res["Heart Rate [beats per minute]"],
                      hematocrit: res["Hematocrit [percentage]"],
                      hemoglobin: res["Hemoglobin [g/dL]"],
                      ibi: res["IBI [miliseconds]"],
                      ldl: res["LDL [mg/dL]"],
                      mean_atrial_pressure: res["Mean Artial Pressure [mmHg]"],
                      mean_corpuscular_hemoglobin:
                        res["Mean Corpuscular Hemoglobin [pg/cells]"],
                      mean_corpuscular_volume:
                        res["Mean Corpuscular Volume [fl]"],
                      mean_plasma_glucose: res["Mean Plasma Glucose [mg/dL]"],
                      oxygen_saturation: res["Oxygen Saturation [percentage]"],
                      pulse_pressure: res["Pulse Pressure [mmHg]"],
                      pulse_rate_quotient:
                        res["Pulse Rate Quotient [beats per breath]"],
                      rbc_count: res["RBC Count [million cells/mcL]"],
                      rmssd: res["RMSSD [miliseconds]"],
                      random_blood_sugar: res["Random Blood Sugar [mg/dL]"],
                      sbp:
                        localStorage.getItem("accno") === "NDC_DC" &&
                        !localStorage.getItem("accno").startsWith("Hiims")
                          ? res["SBP_Old"]
                          : res["SBP [mmHg]"],
                      sd1: res["SD1 [miliseconds]"],
                      sd2: res["SD2 [miliseconds]"],
                      sdnn: res["SDNN [miliseconds]"],
                      stress_index: res["Stress Index"],
                      stroke_volume: res["Stroke Volume [milliliters]"],
                      subcutaneous_fat: res["Subcutaneous Fat [percentage]"],
                      total_cholestrol: res["Total Cholesterol [mg/dL]"],
                      triglycerides: res["Triglycerides [mg/dL]"],
                      vldl: res["VLDL [mg/dL]"],
                      visceral_fat: res["Visceral Fat [percentage]"],
                      true_rbs_fbs: formData.get("rbs_fbs"),
                      true_sbp: res["SBP [mmHg"],
                      true_dbp: res["DBP [mmHg]"],
                      weightChanged: formData.get("weightChanged"),
                      mealTimeElapsed: formData.get("mealTimeElapsed"),
                      hypertension: formData.get("hypertension"),
                      healthIssues: formData.get("healthIssues"),
                      familyDiabetes: formData.get("familyDiabetes"),
                      familyHypertension: formData.get("familyHypertension"),
                      medication: formData.get("medication"),

                      // New fields
                      t3: res["(T3) Triiodothyronine [ng/dL]"],
                      t4: res["(T4) Thyroxine [μg/dL]"],
                      tsh: res["(TSH) Thyroid Stimulating Hormone [μIU/mL]"],
                      blood_volume: res["Blood Volume [mL]"],
                      ibi: res["IBI [miliseconds]"],
                      // mean_arterial_pressure: res["Mean Artial Pressure [mmHg]"],
                      mean_plasma_glucose: res["Mean Plasma Glucose [mg/dL]"],
                      rbc_count: res["RBC Count [million cells/mcL]"],
                      vldl: res["VLDL [mg/dL]"],
                      hr_mad: res["hr_mad"],
                      pnn20: res["pnn20"],
                      pnn50: res["pnn50"],
                      s: res["s"],
                      sd1_sd2: res["sd1/sd2"],
                      vlf: res["vlf"],
                      K_value: res["K_value"],
                      Spectral_Energy: res["Spectral_Energy"],
                      LF_Power: res["LF_Power"],
                      HF_Power: res["HF_Power"],
                      Amp: res["Amp"],
                      Amid: res["Amid"],
                      Dmid: res["Dmid"],
                      mNPV: res["mNPV"],
                    };

                    console.log("res is printed here", res);
                    uploadDataToDatabase(
                      vitalsData,
                      JSON.stringify(rgbDatas),
                      navigate
                    );

                    setsenddata(vitalsData);
                    console.log(senddata);
                    if (token) {
                      axios.delete(`https://iterve.aivot.ai/token`, {
                        params: { token },
                      });
                    }

                    console.log(res, [...formData.entries()]);
                  })
                )
                .catch((e) => console.log(e.message));
              alertWDC("Some noise detected, please retest");
            }
          })
        );
    }
  }, [detectionInProgress]);

  useEffect(() => {
    if (document.getElementById("record-btn"))
      document.getElementById("record-btn").style.backgroundColor =
        startRecording ? "#444" : "#000";
    if (startRecording && model) {
      let elapsedTime = 0;
      if (!detectionInProgress) {
        setDetectionInProgress(true);
      }
      navigator.mediaDevices
        .getUserMedia({
          video: {
            width: 640,
            height: 480,
          },
        })
        .then((stream) => {
          // function clockTick() {
          //   if (elapsedTime > maxTime || !startRecording) {
          //     setProgress(0);
          //     setRecording(false);
          //   } else {
          //     const newProgress = Math.min((elapsedTime / maxTime) * 100, 100);
          //     setProgress(newProgress);
          //     elapsedTime += interval;
          //     setTimeout(clockTick, interval);
          //   }
          // }
          // clockTick();
        });
    }
    if (!startRecording) {
      setDetectionInProgress(false);
    }
    return () => {
      setDetectionInProgress(false);
      if (recorder) {
        setRecorder(null);
      }
      setProgress(0);
    };
  }, [startRecording, model]);

  return (
    <div className={classes.column}>
      {!isAlertActive && (
        <div className={classes.webcamrecorder}>
          <div className={classes.circularframe} id="webcam-container">
            <canvas
              className={classes.webcamOverlay}
              id="webcam-overlay"
            ></canvas>
            <Webcam
              audio={false}
              videoConstraints={{
                facingMode: "user",
              }}
              ref={webcamRef}
              screenshotFormat="image/jpeg"
              className={classes.webcam}
              mirrored
            />
          </div>
          <div className={classes.progressbar}>
            <CircularProgressbar
              value={progress}
              styles={buildStyles({
                rotation: 0,
                strokeLinecap: "butt",
                pathColor: "rgba(201, 124, 229, 1)",
              })}
            />
          </div>
        </div>
      )}
    </div>
  );
}

export default WebcamRecorder;
