import React, { useEffect, useState } from "react";
import classes from "./reports.module.css";
import axios from "axios";
import Card from "./Card.js";
import userdata from "../typesofusers.json";

const PdfIcons = ({ showdata, navigate, dataCount, accno }) => {
  const [pageInd, setPageInd] = useState(1);
  var link = "";
  const [showdataInCurrentPage, setShowdataInCurrentPage] = useState(
    showdata ? showdata : []
  );
  const docCount = showdata
    ? showdata.length > 0
      ? showdata.length
      : dataCount
    : dataCount;
  console.log("showData", showdata);
  console.log("data count", docCount);

  useEffect(() => {
    setShowdataInCurrentPage(showdata);
    setPageInd(1);
  }, [showdata]);

  useEffect(() => {
    console.log("prefix", showdata);
    if (showdata.length > 0) {
      setShowdataInCurrentPage(
        showdata.slice((pageInd - 1) * 15, Math.min(pageInd * 15, docCount))
      );
    } else {
      if (userdata.form2user && userdata.form2user.includes(accno)) {
        link = `https://iterve.aivot.ai/apollouser1/fetchChunk`;
      } else if (userdata.form3user && userdata.form3user.includes(accno)) {
        link = `https://iterve.aivot.ai/palmuser/fetchChunk`;
      } else {
        link = `https://iterve.aivot.ai/apollouser/fetchChunk`;
      }
      axios
        .get(link, {
          params: {
            accno: accno,
            startInd: docCount - Math.min(pageInd * 15, docCount),
            endInd: docCount - (pageInd - 1) * 15,
          },
        })
        .then((res) => {
          console.log("chunk", res.data);
          setShowdataInCurrentPage(res.data.vitalsData.reverse());
        });
    }
  }, [pageInd, showdata]);

  const navTo = (numText) => {
    setTimeout(() => {
      if (numText === "") return;
      const num = parseInt(numText);
      if (num < 1 || num > Math.floor(docCount / 15) + (docCount % 15 > 0))
        return;
      setPageInd(num);
    }, 500);
  };

  const valPageInds = (val) => {
    if (val === "") return "";
    const pageNum = parseInt(val);
    if (
      pageNum < 1 ||
      pageNum > Math.floor(docCount / 15) + (docCount % 15 > 0)
    )
      return "";
    return val;
  };

  return (
    <div className={classes.pdfPages}>
      <div id="pdfIconsContainer" className={classes.cardcont}>
        {showdataInCurrentPage.map((item, index, date) => (
          <Card
            key={index}
            name={item.name}
            date={item.createdAt}
            d={item}
            navigate={navigate}
          />
        ))}
      </div>
      <div className={classes.pageNavigationBar}>
        <div className={classes.arrow} onClick={() => navTo(`${pageInd - 1}`)}>
          {" "}
          {"<<"}
        </div>
        <div className={classes.pageLinks}>
          <div
            className={classes.pageInd}
            style={{ color: "grey" }}
            onClick={(e) => navTo(e.target.innerText)}
          >
            {valPageInds(pageInd - 1)}
          </div>
          <div className={classes.circleDot}></div>
          <div
            className={classes.pageInd}
            onClick={(e) => navTo(e.target.innerText)}
          >
            {valPageInds(pageInd)}
          </div>
          <div className={classes.circleDot}></div>
          <div
            className={classes.pageInd}
            style={{ color: "grey" }}
            onClick={(e) => navTo(e.target.innerText)}
          >
            {valPageInds(pageInd + 1)}
          </div>
        </div>
        <div className={classes.arrow} onClick={() => navTo(`${pageInd + 1}`)}>
          {">>"}
        </div>
      </div>
    </div>
  );
};

export default PdfIcons;
