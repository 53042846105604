import React, { useState, useEffect } from "react";

const Form1 = ({ location, goToNextForm, classes, formJsonData, alertWDC }) => {
  // console.log(formJsonData.get("name"));
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phoneNumber: "",
    age: "",
    weight: "",
    height: "",
    gender: "Male",
    activityFactor: "1.2",
    rbs_fbs: "",
    sbp: "",
    dbp: "",
  });

  useEffect(() => {
    if (formJsonData && formJsonData.entries) {
      const newFormData = {};
      for (const entry of formJsonData.entries()) {
        newFormData[entry[0]] = entry[1];
      }
      setFormData((prevState) => ({ ...prevState, ...newFormData }));
    }
  }, [formJsonData]);

  const handleInputChange = (e) => {
    setFormData({
      ...formData,
      [e.target.id]: e.target.value,
    });
  };

  return (
    <>
      <div className={classes.formRow}>
        <div className={classes.formElement}>
          <div className={classes.inputLabel}>
            Name:<span className={classes.requiredStar}>*</span>
          </div>
          <input
            required
            autoComplete="off"
            id="name"
            className={classes.formInput}
            placeholder="Enter your name"
            value={formData.name}
            onChange={handleInputChange}
          />
        </div>
        <div className={classes.formElement}>
          <div className={classes.inputLabel}>
            Email ID:
            {location.state !== "fromApolloMember" ? (
              <span className={classes.requiredStar}>*</span>
            ) : (
              ""
            )}
          </div>
          <input
            autoComplete="off"
            id="email"
            className={classes.formInput}
            placeholder="Enter your email"
            required={location.state !== "fromApolloMember"}
            value={formData.email}
            onChange={handleInputChange}
          />
        </div>
      </div>
      <div className={classes.formRow}>
        <div className={classes.formElement}>
          <div className={classes.inputLabel}>
            Mobile Number:<span className={classes.requiredStar}>*</span>
          </div>
          <input
            required
            autoComplete="off"
            id="phoneNumber"
            type="number"
            className={classes.formInput}
            placeholder="Enter your mobile number"
            value={formData.phoneNumber}
            onChange={handleInputChange}
          />
        </div>
        <div className={classes.formElement}>
          <div className={classes.inputLabel}>
            Age:<span className={classes.requiredStar}>*</span>
          </div>
          <input
            required
            autoComplete="off"
            id="age"
            type="number"
            className={classes.formInput}
            placeholder="Enter your age (in years)"
            value={formData.age}
            onChange={handleInputChange}
          ></input>
        </div>
      </div>
      <div className={classes.formRow}>
        <div className={classes.formElement}>
          <div className={classes.inputLabel}>
            Weight:<span className={classes.requiredStar}>*</span>
          </div>
          <input
            required
            autoComplete="off"
            id="weight"
            className={classes.formInput}
            placeholder="Enter your weight (in kilograms)"
            step="0.01"
            min="20"
            type="number"
            max="200"
            value={formData.weight}
            onChange={handleInputChange}
          />
        </div>
        <div className={classes.formElement}>
          <div className={classes.inputLabel}>
            Height:<span className={classes.requiredStar}>*</span>
          </div>
          <input
            required
            autoComplete="off"
            id="height"
            className={classes.formInput}
            placeholder="Enter your height (in meters)"
            step="0.01"
            min="1"
            type="number"
            max="2.2"
            value={formData.height}
            onChange={handleInputChange}
          ></input>
        </div>
      </div>
      <div className={classes.formRow}>
        <div className={classes.formElement}>
          <div className={classes.inputLabel}>
            Gender:<span className={classes.requiredStar}>*</span>
          </div>
          <select
            id="gender"
            name="Gender"
            className={classes.formInput}
            value={formData.gender}
            onChange={handleInputChange}
          >
            <option value="Male">Male</option>
            <option value="Female">Female</option>
          </select>
        </div>
        <div className={classes.formElement}>
          <div className={classes.inputLabel}>
            Activity factor:
            <span className={classes.requiredStar}>*</span>
          </div>
          <select
            id="activityFactor"
            name="activity_factor"
            className={classes.formInput}
            value={formData.activityFactor}
            onChange={handleInputChange}
          >
            <option value="1.2">Sedentary</option>
            <option value="1.375">Lightly active</option>
            <option value="1.55">Moderately active</option>
            <option value="1.725">Very active</option>
            <option value="1.9">Extra active</option>
          </select>
        </div>
      </div>
      <div className={classes.formRow}>
        <div className={classes.formElement}>
          <div className={classes.inputLabel}>
            True RBS/FBS:<span className={classes.requiredStar}>*</span>
          </div>
          <input
            required
            autoComplete="off"
            id="rbs_fbs"
            className={classes.formInput}
            placeholder="Enter your rbs/fbs"
            type="number"
            value={formData.rbs_fbs}
            onBlur={(e) => {
              const rbs_fbsRange = parseFloat(e.target.value);
              if (rbs_fbsRange < 0) {
                alertWDC("Please enter a valid value of RBS/FBS.");
                e.target.value = "";
                e.target.focus();
                setFormData({
                  ...formData,
                  [e.target.id]: e.target.value,
                });
              }
            }}
            onChange={handleInputChange}
          />
        </div>
        {localStorage.getItem("accno") !== "NDC_DC" &&
        !localStorage.getItem("accno").startsWith("Hiims") ? (
          <>
            <div className={classes.formElementHalf}>
              <div className={classes.inputLabel}>
                True SBP:<span className={classes.requiredStar}>*</span>
              </div>
              <input
                required
                autoComplete="off"
                id="sbp"
                className={classes.formInput}
                placeholder="Enter your sbp"
                value={formData.sbp}
                type="number"
                onBlur={(e) => {
                  const sbpRange = parseFloat(e.target.value);
                  if (sbpRange < formData.dbp) {
                    alertWDC("SBP must be greater than DBP.");
                    e.target.value = "";
                    e.target.focus();
                    setFormData({
                      ...formData,
                      [e.target.id]: e.target.value,
                    });
                  }
                }}
                onChange={handleInputChange}
              />
            </div>
            <div className={classes.formElementHalf}>
              <div className={classes.inputLabel}>
                True DBP:<span className={classes.requiredStar}>*</span>
              </div>
              <input
                required
                autoComplete="off"
                id="dbp"
                type="number"
                className={classes.formInput}
                placeholder="Enter your dbp"
                value={formData.dbp}
                onBlur={(e) => {
                  const dbpRange = parseFloat(e.target.value);
                  if (dbpRange > formData.sbp) {
                    alertWDC("SBP must be greater than DBP.");
                    e.target.value = "";
                    e.target.focus();
                    setFormData({
                      ...formData,
                      [e.target.id]: e.target.value,
                    });
                  }
                }}
                onChange={handleInputChange}
              />
            </div>
          </>
        ) : (
          <></>
        )}
      </div>
      <div className={classes.formRow}>
        <div className={classes.submitSec}>
          <button
            className={classes.recordBtn}
            onClick={goToNextForm}
            id="record-btn"
          >
            Next
          </button>
        </div>
      </div>
    </>
  );
};

export default Form1;
