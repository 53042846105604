import React from "react";
import classes from "./reports.module.css";
import pdficon from "../Images/pdficon.png";
import { useNavigate } from "react-router-dom";
import userdata from "../typesofusers.json";


const Card = ({ name, date, d, navigate }) => {

  function convertToIST(timeStr) {
    // Remove the "T" if it's present
    const cleanTimeStr = timeStr.replace("T", "");

    // Parse the time string (UTC assumed)
    const [hours, minutes, seconds] = cleanTimeStr.split(":").map(Number);

    // Create a date object with UTC time
    const utcDate = new Date();
    utcDate.setUTCHours(hours, minutes, seconds);

    // Add 5 hours and 30 minutes for IST conversion
    const istOffset = 5 * 60 + 30; // 5 hours 30 minutes in minutes
    const istDate = new Date(utcDate.getTime() + istOffset * 60 * 1000);

    // Extract hours and minutes in IST
    const istHours = istDate.getHours().toString().padStart(2, "0");
    const istMinutes = istDate.getMinutes().toString().padStart(2, "0");

    // Return the formatted time in hh:mm
    return `${istHours}:${istMinutes}`;
  }

  const accno = localStorage.getItem("accno");
  const TruncateName = ({ name }) => {
    if (name.length <= 10) {
      return <div className={classes.cardname}>{name}</div>;
    } else {
      const truncatedName = name.substring(0, 10) + "...";
      return <div className={classes.cardname}>{truncatedName}</div>;
    }
  };
  return (
    <div
      className={classes.maincard}
      onClick={() => {
        console.log("d", d);
        if (accno.startsWith("Hiims") || accno === "NDC_DC") {
          navigate("/shortvitals", { state: d });
        } else if (accno === "Demo_1") {
          navigate("/hrvbodycardvitals", { state: d });
        } else if (accno === "Demo_2" || accno === "deepak") {
          navigate("/hrvbodycardhemovitals", { state: d });
        } else if (
          accno === "Guest" ||
          accno === "Test_Dosha" ||
          accno === "HHJamSaheb"
        ) {
          navigate("/doshavitals", { state: d });
        } else if (userdata.form3user && userdata.form3user.includes(accno)) {
          navigate("/reportspalm", { state: d });
        } else if (accno === "SA_Master") {
          navigate("/jamaicavitals", { state: d });
        } else {
          navigate("/vitals", { state: d });
        }
      }}
    >
      <div className={classes.ncont}>
        <div className={classes.smallcircle}></div>
        {TruncateName({ name })}
      </div>
      <div className={classes.iconcont}>
        <img src={pdficon} className={classes.card} />
      </div>
      {localStorage.getItem("accno") === "SA_Master" ? (
        <div className={classes.ptexts1}>
          <div className={classes.p1}>
            Saved on {convertToIST(date.slice(11, 19))}
          </div>
          <div className={classes.p21}>{date.slice(0, 10)}</div>
        </div>
      ) : (
        <div className={classes.ptexts}>
          <div className={classes.p1}>Saved on {date.slice(0, 10)}</div>
          <div className={classes.p2}>Created on {date.slice(0, 10)}</div>
        </div>
      )}
    </div>
  );
};

export default Card;
