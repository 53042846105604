export const updateInput = (e = null, alertWDC) => {
  if (e) e.preventDefault();

  const updatedFormData = {
    name: document.getElementById("name").value,
    email:
      document.getElementById("email").value === ""
        ? ""
        : document.getElementById("email").value,
    phoneNumber: document.getElementById("phoneNumber").value,
    age: document.getElementById("age").value,
    weight: document.getElementById("weight").value,
    height: document.getElementById("height").value,
    gender: document.getElementById("gender").value,
    diabetic: document.getElementById("diabetic").value,
    activity_factor: document.getElementById("activityFactor").value,
    ...(document.getElementById("label1") && {
      label1: document.getElementById("label1").value,
    }), // Add label1 only if it exists
    ...(document.getElementById("label2") && {
      label2: document.getElementById("label2").value,
    }),
  };

  if (validateForm(updatedFormData, alertWDC)) {
    const formData = new FormData();
    Object.entries(updatedFormData).map(([key, value]) =>
      formData.append(key, value)
    );
    return formData;
  }
  return false;
};

export const validateForm = (formData, alertWDC) => {
  // Regular expressions for validation
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  const mobileRegex = /^\d{10}$/;
  const ageRegex = /^\d+$/;
  const weightRegex = /^\d+(\.\d+)?$/;
  const heightRegex = /^\d+(\.\d+)?$/;

  // Validate each field
  if (!formData.name.trim()) {
    alertWDC("Please enter a name.");
    return false;
  }

  if (formData.email !== "" && !emailRegex.test(formData.email)) {
    alertWDC("Please enter a valid email address.", formData.email);
    return false;
  }
  if (formData.email === "" && document.getElementById("email").required) {
    alertWDC("Please enter a valid email address.", formData.email);
    return false;
  }

  if (!mobileRegex.test(formData.phoneNumber)) {
    alertWDC("Please enter a valid 10-digit mobile number.");
    return false;
  }

  if (!ageRegex.test(formData.age)) {
    alertWDC("Please enter a valid age.");
    return false;
  }

  if (!weightRegex.test(formData.weight)) {
    alertWDC("Please enter a valid weight.");
    return false;
  }

  if (!heightRegex.test(formData.height)) {
    alertWDC("Please enter a valid height.");
    return false;
  }
  if (formData.label1) {
    if (!formData.label1.trim()) {
      alertWDC("Please enter Nadi.");
      return false;
    }
  }
  if (formData.label2) {
    if (!formData.label2.trim()) {
      alertWDC("Please enter Patient History.");
      return false;
    }
  }

  // Validate gender
  if (formData.gender !== "Male" && formData.gender !== "Female") {
    alertWDC("Please select a valid gender (Male/Female).");
    return false;
  }

  // Validate diabetic
  if (formData.diabetic !== "yes" && formData.diabetic !== "no") {
    alertWDC("Please select a valid option for diabetic (yes/no).");
    return false;
  }

  return true; // Form is valid
};
