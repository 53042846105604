export const updateInput = (e = null) => {
  if (e) e.preventDefault();

  var updatedFormData;

  if (
    localStorage.getItem("accno") === "Jintur1" ||
    localStorage.getItem("accno") === "Jintur2"
  ) {
    updatedFormData = {
      name: document.getElementById("name").value,
      email:
        document.getElementById("email").value === ""
          ? ""
          : document.getElementById("email").value,
      phoneNumber: document.getElementById("phoneNumber").value,
      age: document.getElementById("age").value,
      weight: document.getElementById("weight").value,
      height: document.getElementById("height").value,
      gender: document.getElementById("gender").value,
      Diabetic: document.getElementById("diabetic").value,
      Activity_Factor: document.getElementById("activityFactor").value,
      rbs: document.getElementById("rbs_fbs").value,
    };
  } else {
    updatedFormData = {
      name: document.getElementById("name").value,
      email:
        document.getElementById("email").value === ""
          ? ""
          : document.getElementById("email").value,
      phoneNumber: document.getElementById("phoneNumber").value,
      age: document.getElementById("age").value,
      weight: document.getElementById("weight").value,
      height: document.getElementById("height").value,
      gender: document.getElementById("gender").value,
      Diabetic: document.getElementById("diabetic").value,
      Activity_Factor: document.getElementById("activityFactor").value,
    };
  }

  if (validateForm(updatedFormData)) {
    const formData = new FormData();
    Object.entries(updatedFormData).map(([key, value]) =>
      formData.append(key, value)
    );
    return formData;
  }
  return false;
};

export const validateForm = (formData) => {
  // Regular expressions for validation
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  const mobileRegex = /^\d{10}$/;
  const ageRegex = /^\d+$/;
  const weightRegex = /^\d+(\.\d+)?$/;
  const heightRegex = /^\d+(\.\d+)?$/;

  // Validate each field
  if (!formData.name.trim()) {
    alert("Please enter a name.");
    return false;
  }

  if (formData.email !== "" && !emailRegex.test(formData.email)) {
    alert("Please enter a valid email address.", formData.email);
    return false;
  }
  if (formData.email === "" && document.getElementById("email").required) {
    alert("Please enter a valid email address.", formData.email);
    return false;
  }

  if (!mobileRegex.test(formData.phoneNumber)) {
    alert("Please enter a valid 10-digit mobile number.");
    return false;
  }

  if (!ageRegex.test(formData.age)) {
    alert("Please enter a valid age.");
    return false;
  }

  if (!weightRegex.test(formData.weight)) {
    alert("Please enter a valid weight.");
    return false;
  }

  if (!heightRegex.test(formData.height)) {
    alert("Please enter a valid height.");
    return false;
  }

  // Validate gender
  if (formData.gender !== "0" && formData.gender !== "1") {
    alert("Please select a valid gender (Male/Female).");
    return false;
  }

  // Validate diabetic
  if (formData.Diabetic !== "0" && formData.Diabetic !== "1") {
    alert("Please select a valid option for diabetic (yes/no).");
    return false;
  }

  return true; // Form is valid
};
